<template>
  <b-form-group
    label="Durum"
    label-for="status"
  >
    <validation-provider
      #default="{ errors }"
      name="Durum"
      rules="required"
    >
      <v-select
        v-model="dataItem.status"
        :options="status"
        label="title"
        :reduce="item => item.id"
        placeholder="Durum"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'BlogCategory',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      status: [
        {
          id: '1',
          title: 'Aktif',
        },
        {
          id: '0',
          title: 'Pasif',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.status
    },
    dataItem() {
      return this.$store.getters['popupImages/dataItem']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
