<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col cols="12">
          <date-cards />
        </b-col>
        <b-col cols="12">
          <url />
        </b-col>
        <b-col
          cols="12"
        >
          <status />
        </b-col>

      </b-row>
    </b-card>
    <image-card />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'

import Status from '@/views/Admin/PopupImages/elements/Status.vue'
import TitleText from '@/views/Admin/PopupImages/elements/Title.vue'
import Url from '@/views/Admin/PopupImages/elements/Url.vue'
import ImageCard from '@/views/Admin/PopupImages/elements/Image.vue'
import DateCards from '@/views/Admin/PopupImages/elements/DateCards.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    Status,
    TitleText,
    Url,
    ImageCard,
    DateCards,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['popupImages/dataItem']
    },
  },
}
</script>
