<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Başlangıç Tarihi"
        label-for="sdate"
      >
        <validation-provider
          #default="{ errors }"
          name="Başlangıç Tarihi"
          rules="required"
        >
          <b-form-datepicker
            id="sdate"
            v-model="dataItem.sdate"
            placeholder="Başlangıç Tarihi"
            v-bind="{labelNoDateSelected: 'Haber Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Bitiş Tarihi"
        label-for="edate"
      >
        <validation-provider
          #default="{ errors }"
          name="Bitiş Tarihi"
          rules="required"
        >
          <b-form-datepicker
            id="edate"
            v-model="dataItem.edate"
            placeholder="Bitiş Tarihi"
            v-bind="{labelNoDateSelected: 'Haber Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>

</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormDatepicker, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Url',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['popupImages/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
